import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

class Bio extends React.Component {
    twitter() {
        if (this.props.twitter) {
            return <p>You should <a href={"https://twitter.com/" + this.props.twitter} target="_blank">follow him</a> on Twitter</p>
        }
    }

    about() {
        if (this.props.about) {
            return <span> - {this.props.about}</span>
        }
    }

    render() {
        const photo = this.props.photo ?
        //     <Img
        //     fixed={this.props.photo.childImageSharp.fixed}
        //     alt={this.props.author}
        // />
            <GatsbyImage
                image={
                    this.props.photo.childImageSharp.gatsbyImageData
                }
            />
            : null;

        return (
            <div className="grid-x">
                <div className="cell medium-1">
                    {photo}
                </div>
                <div className="cell medium-10 medium-offset-1">
                    Written by <strong>{this.props.author}</strong>{this.about()}
                    {this.twitter()}
                </div>
            </div>
        )
    }
}

export default Bio
