import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { FaClock } from 'react-icons/fa/index'
import { GatsbyImage } from 'gatsby-plugin-image'
import Bio from '../components/Bio'
import { graphql } from 'gatsby'
//import Disqus from 'disqus-react';
import Layout from '../layouts/index'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const featuredImage = post.frontmatter.featured_image ? (
      <GatsbyImage
        image={post.frontmatter.featured_image.childImageSharp.gatsbyImageData}
      />
    ) : (
      // <Img
      //     fluid={post.frontmatter.featured_image.childImageSharp.fluid}
      //     fadeIn={true}
      // />
      <p>image</p>
    )

    const postURL = 'https://www.mobelop.com' + post.fields.slug
    // const disqusShortname = 'Mobelop.com';
    // const disqusConfig = {
    //     url: postURL,
    //     identifier: post.fields.slug,
    //     title: post.frontmatter.title,
    // };

    let comments = null
    // if (typeof window !== 'undefined' && window.dataLayer !== undefined) {
    // comments = <Disqus.DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />;
    // }

    return (
      <Layout location={this.props.location}>
        <div className="entry">
          <Helmet title={`${post.frontmatter.title} | ${siteTitle}`}>
            <meta property="og:url" content={postURL} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta
              property="og:image"
              content={
                'https://www.mobelop.com' +
                post.frontmatter.featured_image.childImageSharp.gatsbyImageData
                  .src
              }
            />
          </Helmet>
          {featuredImage}
          <h1>{post.frontmatter.title}</h1>
          <p className="entry-meta" title="Publication date">
            <FaClock size={14} /> {post.frontmatter.date}
          </p>

          <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <div className="entry-author">
            <Bio
              author={post.frontmatter.author}
              photo={post.frontmatter.authorPhoto}
              twitter={post.frontmatter.authorTwitter}
              about={post.frontmatter.authorAbout}
            />
          </div>

          {comments}
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }

      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        authorTwitter
        authorAbout
        authorPhoto {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 64)
          }
        }

        featured_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
